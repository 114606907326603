import React, { useEffect, useState } from "react";

import "./AboutMe.css"; // Import the CSS file
import "./console.css";
import { FaGithub, FaLinkedin, FaKaggle } from "react-icons/fa"; // Import GitHub and LinkedIn icons
import wave from "./wave.jpg";
import NavMenu from "./NavMenu";

const AboutMe = () => {
  const [typedText, setTypedText] = useState(""); // State to hold the typed text
  const [isDeleting, setIsDeleting] = useState(false); // Track if text is being deleted
  const [currentWordIndex, setCurrentWordIndex] = useState(0); // Track word index
  const [typingSpeed, setTypingSpeed] = useState(150); // Typing speed

  // Words to type and delete
  const words = ["Software Developer", "Data Scientist", "Engineer"];
  const text = "Ian Wells, "; // Static text part
  const delayBetweenWords = 1000; // Delay between typing/deleting words

  const handleTyping = () => {
    const fullText = text + words[currentWordIndex]; // Full text with the dynamic word

    if (!isDeleting) {
      // Typing animation: Add one character at a time
      setTypedText(fullText.substring(0, typedText.length + 1));

      // Once the full word is typed
      if (typedText === fullText) {
        if (currentWordIndex < words.length - 1) {
          // Only delete if we're not on the last word
          setTimeout(() => setIsDeleting(true), delayBetweenWords); // Delay before deleting
        }
      }
    } else {
      // Deleting animation: Remove one character at a time
      setTypedText(fullText.substring(0, typedText.length - 1));

      // Once the word is fully deleted
      if (typedText === text) {
        setIsDeleting(false);
        setCurrentWordIndex((prevIndex) => prevIndex + 1); // Move to the next word
      }
    }
  };

  useEffect(() => {
    // If we've reached the last word, stop the typing effect
    if (currentWordIndex >= words.length) return;

    // Set timeout for typing or deleting based on the current mode
    if (isDeleting) {
      setTypingSpeed(100); // Faster when deleting
    } else {
      setTypingSpeed(150); // Slower when typing
    }

    const typingTimeout = setTimeout(() => {
      handleTyping();
    }, typingSpeed);

    // Cleanup timeout when component unmounts
    return () => clearTimeout(typingTimeout);
  }, [typedText, isDeleting, currentWordIndex, typingSpeed, words]); // Include typingSpeed and words

  return (
    <section className="about-section" id="about">
      <div class="container">
        <div className="icon-container">
          <a
            href="https://github.com/IanCWells"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub className="icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/ianchristopherwells/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="icon" />
          </a>
          <a
            href="https://www.kaggle.com/iancwells"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaKaggle className="icon" />
          </a>
        </div>

        <div className="typing-container">
          <span className="typing-text">{typedText}</span>
          <span className="cursor">|</span>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
