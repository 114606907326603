import React, { useState, useEffect, useRef } from "react";
import "./NavMenu.css"; // Import your custom CSS

const NavMenu = () => {
  const [isOpen, setIsOpen] = useState(false); // State to track if the menu is open
  const menuRef = useRef(null); // Reference for the menu to detect outside clicks

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu open/close state
  };

  // Close the menu if a click is detected outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setIsOpen(false); // Close the menu if clicked outside
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <nav className="nav-menu" ref={menuRef}>
      {/* Hamburger icon */}
      <button className="menu-button" onClick={toggleMenu}>
        &#9776; {/* HTML code for the hamburger icon */}
      </button>

      {/* Menu links, shown only if menu is open */}
      <ul className={`menu-links ${isOpen ? "open" : ""}`}>
        <li>
          <a href="#about" onClick={toggleMenu}>
            Home
          </a>
        </li>
        <li>
          <a href="#info" onClick={toggleMenu}>
            About
          </a>
        </li>
        <li>
          <a href="#projects" onClick={toggleMenu}>
            Projects
          </a>
        </li>
        <li>
          <a href="#learn" onClick={toggleMenu}>
            Influences
          </a>
        </li>
        <li>
          <a href="#contact" onClick={toggleMenu}>
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default NavMenu;
