import React, { useEffect, useRef } from "react";
import "./Info.css"; // Import your CSS
import { FaGithub, FaLinkedin } from "react-icons/fa"; // Import GitHub and LinkedIn icons
import myImage from "./ian.png"; // Adjust the path to your image

const Info = () => {
  const infoImageRef = useRef(null);

  useEffect(() => {
    const currentRef = infoImageRef.current; // Store the current ref value

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.disconnect(); // Stop observing after the first intersection
          }
        });
      },
      { threshold: 0.8 } // Trigger when 50% of the section is visible
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use the stored ref value
      }
    };
  }, []);

  return (
    <section className="info-section" id="about">
      <div className="info-container">
        {/* Image of you */}
        <div className="info-image" ref={infoImageRef}>
          <img src={myImage} alt="Ian Wells" />
        </div>

        {/* Paragraph about you */}
        <div className="info-text">
          <h1>About Me</h1>
          <p>
            Hi, I'm Ian Wells, an engineer with a deep passion for crafting
            innovative software and data science solutions. I graduated from
            UCLA as an Electrical Engineer, where I developed a foundation in
            electrical systems, programming, and low-level computer
            architecture. When I'm not coding, I'm usually playing guitar,
            sailing, or picking up a new hobby.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Info;
