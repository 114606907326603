import React, { useRef } from "react"; // Correct import
import emailjs from "@emailjs/browser";
import "./Contact.css"; // Update the path based on your project structure

const Contact = () => {
  const form = useRef();

  // Initialize EmailJS with your public key
  emailjs.init("pA9GvQNTgpcY1oXBU");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_3wjsonu", "template_pttbjsb", form.current).then(
      (result) => {
        console.log("SUCCESS!", result.text);
        alert("Message sent successfully!");
      },
      (error) => {
        console.log("FAILED...", error);
        alert("Failed to send message. Please try again.");
      }
    );
  };

  return (
    <div className="contact-section">
      <div className="borderBox">
        <div className="contact-title">Contact Me</div>
        <form ref={form} onSubmit={sendEmail} id="contact-form">
          {/* Hidden input to handle contact number (optional) */}
          <input type="hidden" name="contact_number" value="697483" />

          <div className="form-group">
            <label htmlFor="user_name">Name</label>
            <input
              type="text"
              name="user_name"
              id="user_name"
              placeholder="Your Name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="user_email">Email</label>
            <input
              type="email"
              name="user_email"
              id="user_email"
              placeholder="Your Email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              placeholder="Your Message"
              required
            />
          </div>

          <button type="submit" className="contact-submit">
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
