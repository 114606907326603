import React, { useEffect, useRef } from "react";
import "./Learnwithme.css"; // Import your CSS file
import howard from "./howard.png";
import pyTorch from "./pytorch.png";
import beatles from "./beatles.png";
import jimi from "./jimi1.png";
import redBarn from "./redbarn.png";
import vic from "./victor.png";
import nn from "./nn.png";
import llama from "./llama.png";
import ab from "./ab.png";
import tt from "./tedtrucks.png";

// Sample data with image URLs (replace with your actual image URLs and links)
const learnWithMeData = [
  {
    title: "Fast Ai",
    link: "https://course.fast.ai/",
    imageUrl: howard,
  },
  {
    title: "PyTorch for Deep Learning",
    link: "https://www.youtube.com/watch?v=V_xro1bcAuA&list=LL&index=6&t=18530s",
    imageUrl: pyTorch,
  },
  {
    title: "Neural Net from Scratch",
    link: "https://www.youtube.com/watch?v=w8yWXqWQYmU",
    imageUrl: nn,
  },
  {
    title: "Llama Indexing",
    link: "https://www.deeplearning.ai/short-courses/building-agentic-rag-with-llamaindex/",
    imageUrl: llama,
  },
  {
    title: "Don't Let me Down",
    link: "https://www.google.com/search?q=the+beatles+last+performance",
    imageUrl: beatles,
  },
  {
    title: "Eat a Peach",
    link: "https://www.youtube.com/watch?v=ggeab0lKz2c",
    imageUrl: ab,
  },
  {
    title: "Tedeshi Trucks Band",
    link: "https://www.youtube.com/watch?v=ZrSK-0-MQ8s",
    imageUrl: tt,
  },
  {
    title: "Red Barn",
    link: "https://www.youtube.com/watch?v=YI6zVQ7gCtQ",
    imageUrl: redBarn,
  },
  {
    title: "Man's Search for Meaning",
    link: "https://www.amazon.com/Mans-Search-Meaning-Viktor-Frankl/dp/080701429X",
    imageUrl: vic,
  },
];

const LearnWithMe = () => {
  const scrollRef = useRef(null); // Create a ref to track the scroll container
  const headerRef1 = useRef(null); // Create a ref for each header
  const headerRef2 = useRef(null);
  const headerRef3 = useRef(null);
  const isScrollingRef = useRef(false); // Track if the auto-scrolling is active
  const animationFrameRef = useRef(null); // Track requestAnimationFrame ID for canceling

  useEffect(() => {
    const scrollContainer = scrollRef.current;

    const autoScroll = () => {
      if (scrollContainer) {
        scrollContainer.scrollBy({ left: 1, behavior: "smooth" }); // Scroll slightly left
        animationFrameRef.current = requestAnimationFrame(autoScroll); // Continuously scroll
      }
    };

    const startAutoScroll = () => {
      if (!animationFrameRef.current) {
        animationFrameRef.current = requestAnimationFrame(autoScroll); // Start scrolling
      }
    };

    const stopAutoScroll = () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current); // Stop the scroll
        animationFrameRef.current = null;
      }
    };

    // Intersection Observer for auto-scrolling
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isScrollingRef.current) {
            startAutoScroll(); // Start scrolling when the container is in view
            isScrollingRef.current = true;
          } else if (!entry.isIntersecting && isScrollingRef.current) {
            stopAutoScroll(); // Stop scrolling when the container is out of view
            isScrollingRef.current = false;
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    // Intersection Observer for headers animation
    const headerObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show"); // Add "show" class to trigger animations
          }
        });
      },
      { threshold: 0.3 } // Trigger animation when 30% of the header is visible
    );

    // Observe the scroll container
    if (scrollContainer) {
      observer.observe(scrollContainer);
    }

    // Observe the headers
    [headerRef1.current, headerRef2.current, headerRef3.current].forEach(
      (header) => {
        if (header) {
          headerObserver.observe(header);
        }
      }
    );

    // Cleanup: Stop scrolling and disconnect observer when the component unmounts
    return () => {
      stopAutoScroll();
      if (scrollContainer) {
        observer.unobserve(scrollContainer);
      }
      [headerRef1.current, headerRef2.current, headerRef3.current].forEach(
        (header) => {
          if (header) {
            headerObserver.unobserve(header);
          }
        }
      );
    };
  }, []);

  // Stop scrolling when hovering over the scroll-item image
  const handleMouseEnter = () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current); // Stop scroll on hover
      animationFrameRef.current = null;
    }
  };

  // Resume scrolling when the mouse leaves the scroll-item image
  const handleMouseLeave = () => {
    if (!animationFrameRef.current) {
      animationFrameRef.current = requestAnimationFrame(() => {
        const scrollContainer = scrollRef.current;
        if (scrollContainer) {
          scrollContainer.scrollBy({ left: 1, behavior: "smooth" });
        }
        animationFrameRef.current = requestAnimationFrame(handleMouseLeave);
      });
    }
  };

  return (
    <div className="learn-with-me-section">
      {/* Headers with refs for animation */}
      <div className="header" ref={headerRef1}>
        Influences
      </div>
      <div className="header-2" ref={headerRef2}>
        Influences
      </div>
      <div className="header-3" ref={headerRef3}>
        Influences
      </div>

      {/* Scrollable container */}
      <div className="scroll-container" ref={scrollRef}>
        {learnWithMeData.map((item, index) => (
          <div className="scroll-item" key={index}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <img
                src={item.imageUrl}
                alt={item.title}
                className="scroll-item-image"
                onMouseEnter={handleMouseEnter} // Stop scrolling on hover
                onMouseLeave={handleMouseLeave} // Resume scrolling when not hovering
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LearnWithMe;
