import React from "react";
import "./Projects.css"; // You can add styling in this file
import myImage from "./ian.png"; // Adjust the path to your image
import golfWiz from "./golfwiz.png";
import adapAlg from "./AdaptiveAlgs.png";
import openswells from "./openswells.png";
import gtzan from "./gtzan.jpg";
import NaturesNavigators from "./NatureNav.png";
import malloc from "./malloc.png";
import platformer from "./platformer.png";
import pathfinding from "./pathfind.png";
import spymic from "./spymic.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import pythonIcon from "./python.svg"; // Python SVG icon
import arduinoIcon from "./arduino.svg";
import golangIcon from "./golang.svg";
import clogo from "./clogo.svg";
import c_logo from "./c++logo.svg";
import jsLogo from "./js.svg";
import { FaGithub, FaLinkedin, FaKaggle } from "react-icons/fa"; // Import GitHub and LinkedIn icons

const projectsData = [
  {
    title: "Golf Wizard - 2024",
    description:
      "For my Systems Engineering Capstone project, I built on a 2D Golfing Simulator.  Combining elements of computer vision, audio processing, and motion detection, I designed and manufactured a 3D-printed golf controller that interfaced with a central game script.  This project leveraged Python, Arduino, MQTT, inertial measurement units, 3D printing, circuitry, and OpenCV.  Golf Wizards provided valuable hands-on experience in both software and hardware integration.",

    image: golfWiz,
    skills: [pythonIcon, arduinoIcon], // Added an array of skill icons
    docLink:
      "https://docs.google.com/document/d/110nmJTsTIzUx7x9a-3DXwjztlp2fOGsx/edit",
    gitLink: "https://github.com/ECE-180D-WS-2024/Team-4",
  },
  {
    title: "OpenSwells (Ongoing)",
    description:
      "I am currently working on developing swell forecasting models using machine learning, with data sourced from government buoys. I am teaming up with my brilliant friend Evan Coons. Check out openswells.com, where real-time forecasts can be viewed.",

    image: openswells,
    skills: [golangIcon, pythonIcon],
    gitLink: "https://github.com/open-swells",
  },
  {
    title: "Nature's Navigators - 2024",
    description:
      "Developed a graphical simulation to model natural selection among competing groups of animals. Created algorithms to simulate dynamic movement, evolution, and creature conflict.",

    image: NaturesNavigators,
    skills: [jsLogo],
    docLink:
      "https://docs.google.com/document/d/1XsSUVZxCaJ5GRr6QCbDQJ-NIIVGkYZF5T6A_55ljefc/edit",
    gitLink: "https://github.com/IanCWells/NatureNavigators",
  },
  {
    title: "Musical Key Recognizer (Ongoing)",
    description:
      "I am enhancing the GTZAN musical dataset by adding new features and building an application that can recognize the musical key of a song.  The project leverages Convolutional Neural Networks (CNNs), Fourier transforms, and integrates Shazam/Spotify APIs.",
    link: "https://your-portfolio.com",
    image: gtzan,
    skills: [pythonIcon],
    kaggleLink:
      "https://www.kaggle.com/datasets/iancwells/gtzan-with-songtitle-and-artist",
  },
  {
    title: "Adaptive Infection Testing - 2024",
    description:
      "I developed novel algorithms that combine binary and diagonal splitting techniques to optimize both individual and group testing in infected communities. This project heavily involves Python and graph theory.",
    link: "https://your-portfolio.com",
    image: adapAlg,
    skills: [pythonIcon],
    docLink:
      "https://docs.google.com/document/d/18RTM9Aw94NjPt-UEY5uAbSCTACqWTBkOwPNSHjj461Y/edit",
    gitLink: "https://github.com/IanCWells/134AGroupProject",
  },
  {
    title: "Malloc Dynamic Memory Allocator - 2022",
    description:
      "Developed a functional low-level Malloc memory allocator using C. Implementation uses an explicit doubly linked list to track segments of free memory within the heap. Utilized GDB, VIM, and understanding of lower-level assembly language.",
    link: "https://your-portfolio.com",
    image: malloc,
    skills: [clogo],
  },
  {
    title: "2D Platformer - 2022",
    description:
      "40+ hr project using C++. Final product is a multiple level 2D platformer Mario-like video game. I utilized object oriented programming, class hierarchy, polymorphism, and inheritance to create the game. Learned to adequately organize and design coding structure.",
    link: "https://your-portfolio.com",
    image: platformer,
    skills: [c_logo],
  },
  {
    title: "Pathfinding Robot - 2022",
    description:
      "8 week project to design and build a functional pathfinding robot that successfully navigates a maze. Utilizes Arduino, Python, and understanding of circuitry. Concepts of PID controller.",
    link: "https://your-portfolio.com",

    skills: [pythonIcon, arduinoIcon],
    youtubeVideo: "https://www.youtube.com/embed/0nNgpS6YJck", // YouTube embed link
  },
  {
    title: "Spy Microphone - 2021",
    description:
      "Led a team of engineers to design a functional “Spy-Microphone” that successfully amplifies volume and eliminates unwanted sound frequencies using both amplification and high pass filter circuitry. Project uses Arduino software.",
    link: "https://your-portfolio.com",
    image: spymic,
    skills: [pythonIcon],
  },
];

const Projects = () => {
  return (
    <section className="projects-section" id="projects">
      <h2 className="projects-title">Featured Projects</h2>
      <div className="projects-grid">
        {projectsData.map((project, index) => (
          <div className="project-card" key={index}>
            <div className="project-content">
              {/* Flexbox container for the title and the icons */}
              <div className="project-header">
                <h3 className="project-title">{project.title}</h3>

                {/* Icons section */}
                {project.skills && (
                  <div className="icon-location">
                    {project.skills.map((skill, skillIndex) => (
                      <img
                        key={skillIndex}
                        src={skill}
                        alt="Skill Icon"
                        className="project-skill-icon"
                      />
                    ))}
                  </div>
                )}

                <p className="project-description">{project.description}</p>

                <div className="spacing">
                  {/* Conditionally render the Doc button if docLink is present */}
                  {project.docLink && (
                    <button
                      type="submit"
                      className="doc-submit"
                      onClick={() => (window.location.href = project.docLink)}
                    >
                      Doc
                    </button>
                  )}

                  {/* Conditionally render the Git button if gitLink is present */}
                  {project.gitLink && (
                    <button
                      type="submit"
                      className="git-submit"
                      onClick={() => (window.location.href = project.gitLink)}
                    >
                      Git
                    </button>
                  )}

                  {/* Conditionally render the Kaggle button if kaggleLink is present */}
                  {project.kaggleLink && (
                    <button
                      type="submit"
                      className="kag-submit"
                      onClick={() =>
                        (window.location.href = project.kaggleLink)
                      }
                    >
                      <FaKaggle />
                    </button>
                  )}
                </div>
              </div>

              {/* Project image */}
              <div className="project-image">
                {project.youtubeVideo ? (
                  <iframe
                    className="video"
                    src={project.youtubeVideo}
                    title={project.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                ) : (
                  <img src={project.image} alt={project.title} />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
