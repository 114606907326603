import React from "react";
import "./App.css";
import AboutMe from "./AboutMe";
import Projects from "./Projects";
import Article from "./ArticleCard";
import Contact from "./Contact";
import Info from "./Info";
import NavMenu from "./NavMenu"; // Import the navigation menu component
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LearnWithMe from "./Learnwithme.js"; // Import the new component

function App() {
  return (
    <div className="App">
      <NavMenu /> {/* Add the navigation menu */}
      <section id="about">
        <AboutMe />
      </section>
      <section id="info">
        <Info />
      </section>
      <hr
        style={{
          border: "1px solid black",
          width: "calc(100% - 40px)",
          margin: "0px auto",
        }}
      />
      <section id="projects">
        <Projects />
      </section>
      {/* Add the Learn With Me Section */}
      <hr
        style={{
          border: "1px solid black",
          width: "calc(100% - 40px)",
          margin: "0px auto",
        }}
      />
      <section id="learn">
        <LearnWithMe />
      </section>
      {/* FontAwesome Arrow */}
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}

export default App;

//<section id="article">
//        <Article />
//</section>
